import { useState, useEffect } from "react";
import UserSearchCard from "../components/cards/table/UserSearchCard";
import { GridRowParams } from "@mui/x-data-grid";
import UserChartCard from "../components/cards/chart/UserChartCard";
import UserCoinDetailCard from "../components/cards/table/UserCoinDetailCard";
import PlaceholderCard from "../components/cards/policy/PolicyPlaceholderCard";
import { Grow } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { getAccessCookie, checkTokenExp, check, logout } from "../common/apiManager";
import RedirectDialog from "../components/RedirectDialog";
import { isAdmin } from "../common/api";
import { deleteAllCookies } from "../common/utils";

const Users = () => {
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = getAccessCookie();
    console.log("user 페이지", accessToken);

    if (!accessToken) { // 토큰이 없는 경우
      navigate('/');
      return;
    }
    if (!checkTokenExp(accessToken)) { // 토큰이 만료된 경우
      deleteAllCookies();
      setIsDialogOpen(true);
      return;
    }
    if (!isAdmin(accessToken)) {
      navigate('/dashboard')
    }
  }, [navigate]);

  const handleConfirm = () => {
    setIsDialogOpen(false);
    navigate('/'); // 다이얼로그에서 확인 버튼을 누르면 로그인 화면으로 리다이렉트
  };

  const handleRowClick = (params: GridRowParams) => {
    setOpen(false);
    setSelectedUserId(params.row.userId as number);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  return (
    <>
      <RedirectDialog
        open={isDialogOpen}
        handleConfirm={handleConfirm}
      >
        토큰이 만료되어 로그아웃되었습니다. 로그인 화면으로 이동합니다.
      </RedirectDialog>
      <section className="flex flex-col lg:flex-row gap-6">
        <UserSearchCard
          pageSize={4}
          handleRowClick={handleRowClick}
          className="w-full lg:w-[644px]"
        />
        <Grow in={open} timeout={100}>
          <div className="w-full lg:w-[466px]">
            {selectedUserId && (
              <UserChartCard
                userId={selectedUserId}
                pageSize={4}
                className="w-full h-full"
              />
            )}
          </div>
        </Grow>
        {!selectedUserId && (
          <PlaceholderCard className="w-full lg:w-[466px]">
            목록에서 사용자를 선택하세요.
          </PlaceholderCard>
        )}
      </section>
      <Grow in={open} timeout={100}>
        <section className="flex flex-col lg:flex-row gap-6 mt-6">
          {selectedUserId && (
            <UserCoinDetailCard userId={selectedUserId} pageSize={4} className="w-full lg:w-[466px]" />
          )}
        </section>
      </Grow>
    </>
  );
};

export default Users;
