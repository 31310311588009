import React, { useEffect } from 'react';
import SKKU_LOGO from "../../assets/main_logo_eng.png";
import { Logout } from "@mui/icons-material";
import { IAuth, getStudentDetail } from "../../common/api";
import { logout } from "../../common/apiManager";
import { useQuery } from "react-query";

interface HeaderProps {
  login?: IAuth;
}

function Header({ login }: HeaderProps) {
  const { isLoading: userDetailIsLoading, error: userDetailError, data: userDetail } = useQuery(
    ["userDetail", login?.userId],
    () => getStudentDetail(login?.userId ?? 3),
    {
      enabled: !!login?.userId, // login.userId가 truthy 값일 때만 쿼리 실행
    }
  );

  useEffect(() => {
    if (userDetailError) {
      console.error("학생 상세 정보를 가져오는데 실패했습니다.", userDetailError);
    }
  }, [userDetailError]);

  useEffect(() => {
    if (!userDetailIsLoading && userDetail) {
      console.log("학생 상세 정보:", userDetail);
    }
  }, [userDetailIsLoading, userDetail]);

  return (
    <div className="flex items-center justify-between w-full max-w-full px-4 md:px-16 py-4 md:py-8 bg-background">
      <div className="flex items-center gap-2 md:gap-4">
        <img src={SKKU_LOGO} alt="SKKU logo" className="h-6 md:h-8" />
        <span className="hidden md:block text-lightGray text-logo">|</span>
        <span className="text-logo text-sm md:text-base" style={{ whiteSpace: "nowrap" }}>
          킹고코인
        </span>
      </div>
      <div className="flex items-center gap-2 md:gap-4 text-label-l text-onSurface text-xs md:text-base">
        {userDetail && userDetail.name && userDetail.status && (
          <>
            <span>{userDetail.name}</span>
            <span className="hidden md:block">|</span>
            <span>{userDetail.status}</span>
            <span className="hidden md:block">|</span>
            <Logout className="w-4 h-4 md:w-6 md:h-6 hover:cursor-pointer" onClick={logout} />
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
