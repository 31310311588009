import React from 'react';
import { Box, PaginationItem } from '@mui/material';

interface CustomPaginationProps {
  pageCount: number;
  page: number;
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ pageCount, page, onPageChange }) => {
  const renderPaginationItems = () => {
    const items = [];

    // 첫 페이지로 이동
    items.push(
      <PaginationItem
        key="first"
        onClick={(event) => onPageChange(event, 0)}
        page={1}
        type="first"
      />
    );

    // 이전 페이지로 이동
    items.push(
      <PaginationItem
        key="previous"
        onClick={(event) => onPageChange(event, page - 1)}
        page={page}
        type="previous"
        disabled={page === 0}
      />
    );

    if (pageCount <= 5) {
      // 총 페이지가 5개 이하인 경우 모든 페이지를 표시
      for (let i = 1; i <= pageCount; i++) {
        items.push(
          <PaginationItem
            key={i}
            onClick={(event) => onPageChange(event, i - 1)}
            page={i}
            selected={i === page + 1}
          />
        );
      }
    } else {
      // 총 페이지가 5개 이상인 경우 현재 페이지가 다섯 개 중 세 번째 페이지가 되도록 설정
      const startPage = Math.max(1, Math.min(page + 1 - 2, pageCount - 4));

      for (let i = startPage; i < startPage + 5 && i <= pageCount; i++) {
        items.push(
          <PaginationItem
            key={i}
            onClick={(event) => onPageChange(event, i - 1)}
            page={i}
            selected={i === page + 1}
          />
        );
      }
    }

    // 다음 페이지로 이동
    items.push(
      <PaginationItem
        key="next"
        onClick={(event) => onPageChange(event, page + 1)}
        page={page + 2}
        type="next"
        disabled={page === pageCount - 1}
      />
    );

    // 마지막 페이지로 이동
    items.push(
      <PaginationItem
        key="last"
        onClick={(event) => onPageChange(event, pageCount - 1)}
        page={pageCount}
        type="last"
      />
    );

    return items;
  };

  return (
    <Box className="flex justify-center mt-0">
      {renderPaginationItems()}
    </Box>
  );
};

export default CustomPagination;
