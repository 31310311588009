import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import CoinGrantCard from "../components/cards/grant/CoinGrantCard";
import ExcelCoinGrantCard from "../components/cards/grant/ExcelCoinGrantCard";
import AdminCoinDetailCard from "../components/cards/table/AdminCoinDetailCard";
import { check, getAccessCookie, checkTokenExp } from "../common/apiManager";
import Status from "../components/feedback/Status";
import CoinDeductCard from "../components/cards/deduct/CoinDeductCard";
import ExcelCoinDeductCard from "../components/cards/deduct/ExcelCoinDeductCard";
import { Switch, FormControlLabel } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { deleteAllCookies } from "../common/utils";
import { isAdmin } from "../common/api";
import RedirectDialog from "../components/RedirectDialog";

const Coin = () => {
  const [isGrant, setIsGrant] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    isLoading: loginIsLoading,
    error: loginError,
    data: login,
  } = useQuery("login", check);

  const render = !loginIsLoading && !loginError && login;

  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = getAccessCookie();
    console.log("Policies 페이지", accessToken);

    if (!accessToken) { // 토큰이 없는 경우
      navigate('/');
      return;
    }
    if (!checkTokenExp(accessToken)) { // 토큰이 만료된 경우
      deleteAllCookies();
      setIsDialogOpen(true);
      return;
    }
    if (!isAdmin(accessToken)) {
      navigate('/dashboard')
    }
  }, [navigate]);

  const handleConfirm = () => {
    setIsDialogOpen(false);
    navigate('/'); // 다이얼로그에서 확인 버튼을 누르면 로그인 화면으로 리다이렉트
  };

  useEffect(() => {
    if (updateFlag) {
      setUpdateFlag(false); // 상태를 초기화하여 다음 성공을 기다림
    }
  }, [updateFlag]);

  const handleGrantSuccess = () => {
    setUpdateFlag(true);
  };

  const handleDeductSuccess = () => {
    setUpdateFlag(true);
  };

  return (
    <>
      <RedirectDialog
        open={isDialogOpen}
        handleConfirm={handleConfirm}
      >
        토큰이 만료되어 로그아웃되었습니다. 로그인 화면으로 이동합니다.
      </RedirectDialog>
      <Status
        isLoading={loginIsLoading}
        error={loginError}
        isData={login ? true : false}
        className="w-screen max-w-full h-screen"
      />
      <div className="toggle-container">
        <FormControlLabel
          control={<Switch checked={isGrant} onChange={() => setIsGrant(!isGrant)} />}
          label={isGrant ? "코인 부여" : "코인 차감"}
        />
      </div>
      {render && (
        <>
          <section className="flex gap-6">
            {isGrant ? (
              <>
                <CoinGrantCard adId={login.userId} onGrantSuccess={handleGrantSuccess} />
                <ExcelCoinGrantCard adId={login.userId} maxRow={100} onGrantSuccess={handleGrantSuccess} />
              </>
            ) : (
              <>
                <CoinDeductCard adId={login.userId} onDeductSuccess={handleDeductSuccess} />
                <ExcelCoinDeductCard adId={login.userId} maxRow={100} onDeductSuccess={handleDeductSuccess} />
              </>
            )}
          </section>
          <section>
            <AdminCoinDetailCard adId={login.userId} pageSize={4} key={updateFlag ? 'update' : 'no-update'} />
          </section>
        </>
      )}
    </>
  );
};

export default Coin;
