import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";

interface CounterCardProps {
  label: string;
  target?: number;
  deltaPlus?: boolean;
  delta?: number;
  duration?: number;
  className?: string;
  countSize?: string;
  deltaSize?: string;
}

const CounterCard = ({
  label,
  target = 0,
  // deltaPlus = undefined,
  // delta = undefined,
  duration = 1000,
  className,
  countSize,
  // deltaSize = undefined,
}: CounterCardProps) => {
  const [count, setCount] = useState(0);

  // useEffect(() => {
  //   if (target <= 0) return;

  //   // Step size와 Step interval 계산
  //   const stepCount = 500; // 약 500번의 증가를 원함
  //   const stepValue = target / stepCount; // 각 증가에서 더할 값
  //   const stepDuration = duration / stepCount; // 각 증가 단계의 시간 간격

  //   const interval = setInterval(() => {
  //     setCount((prevCount) => {
  //       if (prevCount + stepValue < target) {
  //         return prevCount + stepValue;
  //       } else {
  //         clearInterval(interval);
  //         return target; // 최종 값으로 설정
  //       }
  //     });
  //   }, stepDuration);

  //   return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 인터벌 정리
  // }, [target, duration]);

  useEffect(() => {
    setCount(target);
  }, [target]);

  return (
    <Card className={className}>
      <CardHeader
        title={label}
        titleTypographyProps={{
          variant: "label-l",
          className: "text-xxs sm:text-xs md:text-sm lg:text-md"
        }}
        className="pb-0"
      />
      <CardContent>
        <div className="flex items-end">
          <div className={`${countSize} font-noto-sans-kr text-primary`}>
            {count}
          </div>
          {/* {delta !== undefined && deltaPlus !== undefined && (
            <div className={`${deltaSize} flex ${deltaPlus ? "text-primary" : "text-error"} ml-2`}>
              {delta}
              {deltaPlus ? "▲" : "▼"}
            </div>
          )} */}
        </div>
      </CardContent>
    </Card>
  );
};

export default CounterCard;
