import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { IPolicy, getPolicies, postCoin } from "../../../common/api";
import {
  ICoinForm,
  PL_ID_MANUAL,
  formToGrantedCoin,
  validateStid,
} from "../../../common/apiManager";
import ConfirmDialog from "../../ConfirmDialog";
import Status from "../../feedback/Status";

interface CoinGrantCardProps {
  adId: number;
  onGrantSuccess: () => void;
}

const CoinGrantCard = ({ adId, onGrantSuccess }: CoinGrantCardProps) => {
  const [form, setForm] = useState<ICoinForm>({
    stId: "",
    stName: "",
    provider: "",
    plId: "",
    stDept: "",
    pfName: "",
    title: "",
    point: 0,
    adId: adId,
    gainedDate: dayjs(),
  });

  const [isManual, setIsMenual] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stIdIsValid, setStIdIsValid] = useState(true);

  const {
    isLoading: policiesIsLoading,
    error: policiesError,
    data: policies,
  } = useQuery(["policies"], () => getPolicies(undefined, true, true));

  if (!policies) {
    return null;
  }

  const policyResponse = policies.policyResponse;

  const groupedPolicies: { [key: string]: IPolicy[] } = policyResponse.reduce((acc: { [key: string]: IPolicy[] }, policy) => {
    const { pfName } = policy;
    if (!acc[pfName]) {
      acc[pfName] = [];
    }
    acc[pfName].push(policy);
    return acc;
  }, {});

  const render =
    !policiesIsLoading && !policiesError && policyResponse && policyResponse.length > 0;

  const handleGrantCoin = () => {
    postCoin(formToGrantedCoin(form)).then(() => {
      onGrantSuccess(); // 부모 컴포넌트의 콜백 호출
      // 코인 부여 후 상태 초기화
      setForm({
        stId: "",
        stName: "",
        provider: "",
        plId: "",
        stDept: "",
        pfName: "",
        title: "",
        point: 0,
        adId: adId,
        gainedDate: dayjs(),
      });
    });
  };

  return (
    <>
      <ConfirmDialog
        open={showModal}
        handleConfirm={() => {
          setShowModal(false); // 모달 닫기
          handleGrantCoin();
        }}
        handleCancel={() => {
          setShowModal(false);
        }}
      >
        {`${form.stName} 학생에게 ${form.point}코인을 부여하시겠습니까?`}
      </ConfirmDialog>
      <Card className="w-[466px]">
        <CardHeader
          title={"학생 코인부여"}
          titleTypographyProps={{ variant: "display" }}
        />
        <CardContent>
          <Status
            isLoading={policiesIsLoading}
            error={policiesError}
            isData={policyResponse && policyResponse.length > 0}
            className="h-[332px]"
          />
          {render && (
            <div className="flex flex-col gap-3 h-[332px]">
              <div className="flex gap-2">
                <TextField
                  className="flex-[3]"
                  label="학번"
                  error={!stIdIsValid}
                  value={form.stId}
                  onChange={(e) => {
                    setForm({ ...form, stId: e.target.value });
                  }}
                  size="small"
                  helperText={
                    stIdIsValid ? "" : "학번을 올바르게 입력해주세요."
                  }
                />
                <TextField
                  className="flex-[2]"
                  label="이름"
                  value={form.stName}
                  onChange={(e) => {
                    setForm({ ...form, stName: e.target.value });
                  }}
                  size="small"
                />
              </div>
              <TextField
                select
                label="정책"
                value={form.plId}
                onChange={(e) => {
                  const selectedPolicyId = e.target.value;
                  const policy = policyResponse.find(p => p.plId.toString() === selectedPolicyId);
                  if (policy) {
                    setForm({
                      ...form,
                      plId: policy.plId.toString(),
                      pfName: policy.pfName,
                      point: policy.point,
                      title: policy.plName,
                      provider: policy.pfName
                    });
                    setIsMenual(policy.plId === parseInt(PL_ID_MANUAL, 10));
                  }
                }}
                size="small"
              >
                {Object.entries(groupedPolicies).map(([providerName, policies]) => [
                  <MenuItem
                    key={providerName}
                    value=""
                    disabled
                    sx={{
                      fontWeight: 'bold',
                      color: 'text.primary', // 강제로 텍스트 색상 적용
                      opacity: 1, // 텍스트 투명도 조정
                      '.Mui-disabled': {
                        opacity: 1, // Material UI가 적용하는 기본 스타일을 override
                      }
                    }}
                  >
                    {providerName}
                  </MenuItem>,
                  ...policies.map(policy => (
                    <MenuItem key={policy.plId} value={policy.plId.toString()} style={{ marginLeft: '20px' }}>
                      {policy.plName}
                    </MenuItem>
                  ))
                ])}
              </TextField>
              <TextField
                disabled
                label="제공처"
                value={form.pfName}
                size="small"
              />
              <Collapse in={isManual} mountOnEnter unmountOnExit>
                <TextField
                  className="w-full"
                  placeholder="제목을 입력해주세요."
                  label="제목"
                  size="small"
                  value={form.title}
                  onChange={(e) => {
                    setForm({ ...form, title: e.target.value });
                  }}
                />
              </Collapse>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={form.gainedDate}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      gainedDate: value!,
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              <div className="flex gap-2">
                <TextField
                  className="flex-1"
                  label="코인값"
                  type="number"
                  value={form.point}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      point: parseInt(e.target.value),
                    });
                  }}
                  size="small"
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    // 코인부여 버튼 클릭시 실행할 코드
                    const result = validateStid(form.stId);
                    setStIdIsValid(result);
                    if (result) setShowModal(true);
                  }}
                >
                  코인부여
                </Button>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default CoinGrantCard;
