import router from "./router";
import { StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

const muiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2B6653",
      contrastText: "#F2F2F2",
    },
    secondary: {
      main: "#FF6B0F",
    },
    error: {
      main: "#C54154",
    },
  },
  fontFamily: ["Gugi", "Noto Sans KR"].join(","),
  typography: {
    display: {
      fontFamily: "Noto Sans KR",
      fontSize: "24px",
      fontWeight: "700",
    },
    logo: {
      fontFamily: "Gugi",
      fontSize: "20px",
      fontWeight: "400",
    },
    "title-m": {
      fontFamily: "Noto Sans KR",
      fontSize: "20px",
      fontWeight: "500",
    },
    "title-l": {
      fontFamily: "Noto Sans KR",
      fontSize: "20px",
      fontWeight: "300",
    },

    "label-m": {
      fontFamily: "Noto Sans KR",
      fontSize: "16px",
      fontWeight: "500",
    },
    "label-l": {
      fontFamily: "Noto Sans KR",
      fontSize: "16px",
      fontWeight: "300",
    },
    body: {
      fontFamily: "Noto Sans KR",
      fontSize: "14px",
      fontWeight: "100",
      lineHeight: "2em",
    },
    caption: {
      fontFamily: "Noto Sans KR",
      fontSize: "12px",
      fontWeight: "100",
    },
  },
});

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       onError: (error) => {
//         console.error("Global error handler:", error);
//         // 여기서 글로벌 오류 처리 로직을 추가할 수 있습니다.
//       },
//     },
//   },
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        if (error instanceof Error) {
          alert(error.message); // 사용자에게 에러 메시지 표시
        } else {
          console.error('Unknown error:', error);
        }
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </QueryClientProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
