import SKKU_LOGO from "../../assets/main_logo_eng.png";

function Footer() {
  return (
    <div className="w-screen max-w-full bg-onSurface text-surface p-4">
      <div className="flex justify-between">
        <div className="flex flex-col xs:flex-row items-center gap-2 sm:gap-4 p-2 md:p-4">
          <img src={SKKU_LOGO} alt="SKKU logo" className="h-4 sm:h-6 md:h-8" />
        </div>
        <div className="flex justify-end">
          <a
            className="p-3 text-xxxs sm:text-sm sm:p-2"
            href="https://www.skku.edu/skku/etc/pop_email.do"
            target="_blank"
            rel="noreferrer"
          >
            이메일
          </a>
          <p className="p-1 text-xxxs sm:text-sm sm:p-2">|</p>
          <a
            className="p-3 text-xxxs sm:text-sm sm:p-2"
            href="https://www.skku.edu/skku/etc/pop_email.do"
            target="_blank"
            rel="noreferrer"
          >
            무료수집거부
          </a>
          <p className="p-1 text-xxxs sm:text-sm sm:p-2">|</p>
          <a
            className="p-3 text-xxxs sm:text-sm sm:p-2"
            href="https://www.skku.edu/skku/etc/private.do"
            target="_blank"
            rel="noreferrer"
          >
            개인정보처리방침
          </a>
        </div>
      </div>
      <div>
        <p className="text-xxs sm:text-xs p-2 md:p-4">
          성균관대학교 소프트웨어융합대학
          <br />
          경기도 수원시 장안구 서부로2066 성균관대학교 자연과학캠퍼스
          <br />
          소프트웨어융합대학 Copyrightⓒ2022 SUNGKYUNKWAN UNIVERSITY ALL RIGHTS
          RESERVED.
          <br />
          킹고코인 및 시스템 관련 문의: sosc.ssa.cs@g.skku.edu
          <br />
          유료 계정 (ChatGPT, Colab 등) 대여 관련 문의: suuumin@skku.edu
          <br />
          IT 장비 대여 관련 문의 : cks612@skku.edu
        </p>
      </div>
    </div>
  );
}

export default Footer;
