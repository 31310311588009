import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { getAccessCookie } from "../../common/apiManager";
import { useState } from "react";
import { useEffect } from "react";
import { IAuth, getJWTClaims } from "../../common/api";


const Frame = () => {
  const [login, setLogin] = useState<IAuth | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = getAccessCookie();
    console.log("frame 페이지", accessToken);
    if (accessToken) {
      setLogin(getJWTClaims(accessToken));
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="w-full min-h-screen">
      <Header login={login || undefined} />
      <div className="flex pt-16 bg-primary w-full">
        <Sidebar login={login || undefined} className="w-[200px] flex-shrink-0" />
        <main className="flex items-center justify-center flex-grow py-16 bg-surface overflow-hidden">
          <div className="flex flex-col gap-6 w-full max-w-[1152px] min-h-screen">
            <Outlet />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Frame;
