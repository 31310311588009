import { useQuery } from "react-query";
import { getPolicies, IPolicy } from "../common/api";

const usePolicy = (plId: number) => {
  const {
    isLoading: policiesIsLoading,
    error: policiesError,
    data: policies,
  } = useQuery(["policies"], () => getPolicies(undefined, undefined, undefined));

  const policy = policies?.policyResponse?.find((it: IPolicy) => it.plId === plId);

  return {
    isLoading: policiesIsLoading,
    error: policiesError,
    data: policy,
  };
};

export default usePolicy;
