import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ko";
import { deleteAllCookies, deleteCookie, getCookie, setCookie } from "./utils";
import { getJWTClaims, refreshAccessToken } from "./api";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { jwtDecode } from 'jwt-decode';

/** 직접 입력에 해당하는 plId */
export const PL_ID_MANUAL = "100";
export const PROD_HOST = "https://kingocoin.cs.skku.edu";
export const DEV_HOST = "http://kingocoin-dev.cs.skku.edu:8080";

export enum POLICY_REQUEST_TYPE {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DEACTIVATE = "DEACTIVATE",
  ACTIVATE = "ACTIVATE",
  DELETE = "DELETE",

  // READ = "READ",
  EMPTY = "EMPTY",
}

export enum POLICY_TYPE {
  ACTIVE = "활성",
  INACTIVE = "비활성",
}

export enum USER_ROLE {
  USER = "ROLE_STUDENT",
  ADMIN = "ROLE_ADMIN",
}

export enum JWT_COOKIE {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  P_TOKEN = "pToken",
}

/* Data */

dayjs.locale("ko");
export const stampToDayjs = (stamp: string) => dayjs(stamp);

export const dayjsToFormat = (dayjs: Dayjs) => dayjs.format("YYYY.MM.DD (ddd)");

export const dayjsToStamp = (dayjs: Dayjs) =>
  dayjs.format("YYYY-MM-DDTHH:mm:ss");

export const validateStid = (stId: string) => {
  if (stId.length !== 10) return false;
  const year = parseInt(stId.slice(0, 4));
  if (year < 2000 || year > dayjs().year()) return false;
  const dept = parseInt(stId.slice(4, 6));
  if (dept !== 31 && dept !== 71 && dept !== 72 && dept !== 73) return false;
  return true;
};

export interface ICoinForm {
  stId: string;
  stName: string;
  plId: string;
  stDept: string;
  provider: string;
  pfName: string;
  title: string;
  point: number;
  adId: number;
  gainedDate: Dayjs;
}

/* 입력폼을 postManualCoin api 변수 형식으로 변환 */
export const formToGrantedCoin = ({
  stId,
  stName,
  plId,
  stDept,
  provider,
  title,
  point,
  adId,
  gainedDate,
}: ICoinForm) => {
  const result = {
    stId: parseInt(stId),
    stName,
    stDept,
    provider,
    plId: parseInt(plId),
    title,
    plus: point >= 0,
    point,
    adId,
    gainedDate: dayjsToStamp(gainedDate),
  };
  console.log(result);
  return result;
};

export const formToDeductedCoin = ({
  stId,
  stName,
  plId,
  stDept,
  provider,
  title,
  point,
  adId,
  gainedDate,
}: ICoinForm) => {
  const result = {
    stId: parseInt(stId),
    stName,
    stDept,
    provider,
    plId: parseInt(plId),
    title,
    plus: !(point >= 0),
    point,
    adId,
    gainedDate: dayjsToStamp(gainedDate),
  };
  console.log(result)
  return result;
};

export interface IPolicyRequestForm {
  plId: string;
  pfId: number;
  pfName: string;
  rqName: string;
  rqPlus: boolean;
  rqPoint: number;
  rqAvailable: boolean;
  rqReason: string;
  rqType: POLICY_REQUEST_TYPE;
}

/* 입력폼을 postPolicyRequest api 변수 형식으로 변환 */
export const formToPolicyRequest = ({
  plId,
  pfId,
  pfName,
  rqName,
  rqPlus,
  rqPoint,
  rqAvailable,
  rqReason,
  rqType,
}: IPolicyRequestForm) => {
  const result = {
    pfId,
    plId,
    plName: rqName,
    plus: rqPlus,
    point: rqPoint,
    active: rqAvailable,
  };
  return result;
};

/* Auth */

export const setAccessCookie = (token: string) => {
  setCookie(JWT_COOKIE.ACCESS_TOKEN, token, {
    "max-age": 60 * 60, // access token 만료 기한 (1시간)
    path: "/",
    // secure: true,
    samesite: "strict",
  });
};

export const setRefreshCookie = (token: string) => {
  setCookie(JWT_COOKIE.REFRESH_TOKEN, token, {
    "max-age": 60 * 60 * 24 * 14, // refresh token 만료 기한 (2주)
    path: "/",
    secure: true,
    samesite: "strict",
  });
};

export const getAccessCookie = () => getCookie(JWT_COOKIE.ACCESS_TOKEN);

export const getRefreshCookie = () => getCookie(JWT_COOKIE.REFRESH_TOKEN);

interface DecodedToken {
  exp: number; // JWT 토큰의 만료 시간 (초 단위 UNIX 시간)
}

export const checkTokenExp = (accessToken: string): boolean => {
  try {
    // JWT 토큰을 디코딩하여 만료 시간을 확인
    const decodedToken = jwtDecode<DecodedToken>(accessToken);
    const currentTime = dayjs().unix(); // 현재 시간 (초 단위 UNIX 시간)

    if (decodedToken.exp < currentTime) {
      // 토큰이 만료된 경우
      return false;
    }

    // 토큰이 유효한 경우
    return true;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return false; // 토큰 디코딩에 실패하면 만료된 것으로 간주
  }
};

/** 로그인 여부 확인 */
export const check = async () => {
  // accessToken 확인
  const accessToken = getAccessCookie();
  if (!accessToken) return null;
  let auth = await getJWTClaims(accessToken);
  if (auth) return auth;

  // // refreshToken 확인
  // const refreshToken = getRefreshCookie();
  // if (!refreshToken) return null;
  // const newAccessToken = await refreshAccessToken(refreshToken);
  // if (!newAccessToken) return null;

  // setAccessCookie(newAccessToken);
  // refreshClientToken();

  auth = await getJWTClaims(accessToken);
  console.log("auth: ", auth);
  return auth;
};

export const logout = () => {
  deleteCookie(JWT_COOKIE.ACCESS_TOKEN);
  deleteCookie("pToken", ".skku.edu");
  // deleteCookie(JWT_COOKIE.REFRESH_TOKEN);
  // window.location.replace(DEV_HOST + "/api/logout");
  window.location.replace(PROD_HOST);
};

/* axios settings */
const axiosRequestSuccess = (config: InternalAxiosRequestConfig) => {
  return config;
};

const axiosRequestError = (error: AxiosError) => {
  if (error.request) {
  }
  return Promise.reject(error);
};

const axiosResponesSuccess = (response: AxiosResponse) => {
  return response;
};

const axiosResponesError = async (error: AxiosError) => {

  check()
    .then((auth) => {
      if (!auth) window.location.href = "/login";
    })
    .catch((reason) => Promise.reject(reason));

  return Promise.reject(error);
};

/** JWT 없이 요청할 때 사용하는 axios client */
export const client = axios.create({
  baseURL: PROD_HOST,
});

axios.interceptors.request.use(axiosRequestSuccess, axiosRequestError);
axios.interceptors.response.use(axiosResponesSuccess, axiosResponesError);

/** JWT를 첨부하여 요청할 때 사용하는 axios client */
export const clientWithToken = axios.create({
  baseURL: PROD_HOST,
  withCredentials: true
});

clientWithToken.interceptors.request.use(config => {
  const token = getAccessCookie();
  console.log(token);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
}, error => {
  return Promise.reject(error);
});

// clientWithToken.interceptors.response.use(
//   response => {
//     return axiosResponesSuccess(response);
//   },
//   error => {
//     return Promise.reject(axiosResponesError(error));
//   }
// );

clientWithToken.interceptors.response.use(
  response => {
    return response; // 성공적인 응답 그대로 반환
  },
  error => {
    if (error.response) {
      const { status, data } = error.response;
      console.log(status, data);
      let errorMessage = '알 수 없는 오류가 발생했습니다.';

      if (status === 403 && data.errorCode === 600) {
        errorMessage = `접근 권한이 없습니다. role = ${data.role}`;
      } else if (status === 404) {
        switch (data.errorCode) {
          case 700:
            errorMessage = `해당 유저가 없습니다. userId = ${data.userId}`;
            break;
          case 701:
            errorMessage = `해당 코인이 없습니다. koinId = ${data.koinId}`;
            break;
          case 702:
            errorMessage = `해당 코인 내역이 없습니다. dtId = ${data.dtId}`;
            break;
          case 703:
            errorMessage = `해당 관리자가 없습니다. adId = ${data.adId}`;
            break;
          case 704:
            errorMessage = `해당 학생이 없습니다. stId = ${data.stId}`;
            break;
          case 705:
            errorMessage = `차감할 포인트가 부족합니다. point = ${data.point}`;
            break;
          case 706:
            errorMessage = `해당 학생의 APP_ID가 없습니다. userId = ${data.userId}`;
            break;
          case 707:
            errorMessage = `해당 플랫폼은 존재하지 않습니다.`;
            break;
          case 801:
            errorMessage = `해당 관리자가 없습니다. perId = ${data.perId}`;
            break;
          default:
            errorMessage = '요청한 리소스를 찾을 수 없습니다.';
            break;
        }
      } else if (status === 400) {
        switch (data.errorCode) {
          case 800:
            errorMessage = '올바르지 않은 로그인 접근입니다.';
            break;
          case 900:
            errorMessage = '알 수 없는 오류입니다.';
            break;
          default:
            errorMessage = '잘못된 요청입니다.';
            break;
        }
      } else if (data.name === 'AccessDeniedException') {
        errorMessage = `접근 권한이 없습니다. refreshToken = ${data.refreshToken}`;
      }

      console.error(`Error ${status}: ${errorMessage}`);
      return Promise.reject(new Error(errorMessage)); // 에러 메시지를 포함한 Error 객체 반환
    } else if (error.request) {
      // 요청이 만들어졌지만 응답이 없는 경우
      console.error('Error: No response received from server');
      return Promise.reject(new Error('No response received from server'));
    } else {
      // 요청 설정 중 오류가 발생한 경우
      console.error('Error: ', error.message);
      return Promise.reject(new Error(error.message));
    }
  }
);

export const refreshClientToken = () => {
  clientWithToken.defaults.headers.common[
    "Authorization"
  ] = `bearer ${getAccessCookie()}`;
};