import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Add } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import { getPolicies } from "../../../common/api";
import { POLICY_REQUEST_TYPE, POLICY_TYPE } from "../../../common/apiManager";
import CustomPagination from "../../CustomPagination";
import Status from "../../feedback/Status";

interface PolicySearchCardProps {
  handleRowClick: (type: POLICY_REQUEST_TYPE, plId?: number) => void;
  className?: string;
}

const PolicySearchCard = ({
  handleRowClick,
  className,
}: PolicySearchCardProps) => {
  const [pageSize, setPageSize] = useState(4);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isGrant, setIsGrant] = useState(true);

  const fetchPolicies = async (page: number, size: number, isGrant: boolean) => {
    const data = await getPolicies(undefined, isGrant, true, page, size);
    setTotalRows(data.totalCount);
    return data.policyResponse;
  };

  const {
    isLoading: policiesIsLoading,
    error: policiesError,
    data: policies,
    refetch,
  } = useQuery(
    ["policies", page, pageSize, isGrant],
    () => fetchPolicies(page, pageSize, isGrant),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [page, pageSize, isGrant]);

  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleToggleChange = () => {
    setIsGrant((prev) => !prev);
    setPage(0); // Reset to the first page
  };

  const render =
    !policiesIsLoading && !policiesError && policies && policies.length > 0;

  const columns = [
    { field: "plName", headerName: "정책명", flex: 2, sortable: false },
    { field: "pfName", headerName: "제공처", flex: 1.5, sortable: false },
    {
      field: "coin",
      headerName: "코인",
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const point = params.row.point;
        const plus = params.row.plus;
        return plus ? `+${point}` : `-${point}`;
      },
    },
    {
      field: "active",
      headerName: "상태",
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? "활성" : "비활성",
    },
  ];

  return (
    <div className={className}>
      <Card className="w-full h-full relative">
        <CardHeader
          title="정책 조회"
          titleTypographyProps={{ variant: "display" }}
          subheader={`${totalRows}건`}
          subheaderTypographyProps={{ variant: "label-l", className: "mt-2" }}
          action={
            <Box className="flex items-center">
              <FormControlLabel
                control={<Switch checked={isGrant} onChange={handleToggleChange} />}
                label={<Typography>{isGrant ? "부여 정책" : "차감 정책"}</Typography>}
              />
            </Box>
          }
        />
        <CardContent>
          <Status
            isLoading={policiesIsLoading}
            error={policiesError}
            isData={policies && policies.length > 0}
            className="h-[318.4px]"
          />
          {render && (
            <DataGrid
              className="h-[318.4px]"
              rows={policies}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnMenu
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                    page: 0,
                  },
                },
              }}
              pageSizeOptions={[pageSize]}
              rowCount={totalRows}
              paginationMode="server"
              onRowClick={(params) =>
                handleRowClick(POLICY_REQUEST_TYPE.UPDATE, params.row.plId)
              }
              components={{
                Pagination: () => (
                  <CustomPagination
                    pageCount={Math.ceil(totalRows / pageSize)}
                    page={page}
                    onPageChange={handlePageChange}
                  />
                ),
              }}
              componentsProps={{
                footer: { className: 'border-0 justify-center items-center' },
              }}
            />
          )}
        </CardContent>
        <Box className="absolute right-4 bottom-4">
          <Button
            variant="contained"
            endIcon={<Add />}
            onClick={() => {
              handleRowClick(POLICY_REQUEST_TYPE.CREATE);
            }}
          >
            정책생성
          </Button>
        </Box>
      </Card>
    </div>
  );
};

export default PolicySearchCard;