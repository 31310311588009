import SKKU_EMBLEM from "../assets/skku_emblem_kor.png";
import SOSD_LOGO from "../assets/sosd_logo.svg";
import {
  POLICY_REQUEST_TYPE,
  client,
  clientWithToken,
  refreshClientToken,
  setAccessCookie,
  getAccessCookie,
} from "./apiManager";

import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export const PROD_LOGIN = "https://kingocoin.cs.skku.edu";
export const DEV_LOGIN = "http://kingocoin-dev.cs.skku.edu:8080";

/** api 라우트 정보 */
enum ROUTE {
  STUDENT = "api/user/student",
  COIN = "/api/coin",
  USER = "/api/user",
  POLICY = "/api/policy",
  STATICS = "/api/statics",
  DEV = "/api/dev",
  AUTH = "/api/auth",
}

export enum USER_ROLE {
  USER = "ROLE_STUDENT",
  ADMIN = "ROLE_ADMIN",
}

/** 이동가능한 사이트 정보 */
export const SITES = [
  // {
  //   pfId: 1,
  //   pfName: "온라인명륜당",
  //   pfLogo: null,
  //   pfLink: "https://mrdang.cs.skku.edu",
  // },
  {
    pfId: 1,
    pfName: "SW중심대학사업단 행사 참석",
    pfLogo: process.env.PUBLIC_URL + SKKU_EMBLEM,
    pfLink: "https://skb.skku.edu/swuniv/notice.do",
  },
  {
    pfId: 2,
    pfName: "SW융합대학 행사 참석",
    pfLogo: process.env.PUBLIC_URL + SKKU_EMBLEM,
    pfLink: "https://sw.skku.edu/sw/notice.do",
  },
  {
    pfId: 3,
    pfName: "학과 행사 참석",
    pfLogo: process.env.PUBLIC_URL + SKKU_EMBLEM,
    pfLink:
      "https://cse.skku.edu/cse/notice.do",
  },
  {
    pfId: 4,
    pfName: "오픈소스플랫폼",
    pfLogo: process.env.PUBLIC_URL + SOSD_LOGO,
    pfLink: "https://sosd.skku.edu/community/main",
  },
];

export const PLATFORMS = [
  {
    pfId: 1,
    pfName: '오픈소스플랫폼'
  },
  {
    pfId: 2,
    pfName: '학과 행사 참여'
  },
  {
    pfId: 3,
    pfName: '소프트웨어융합대학 학생회'
  },
  {
    pfId: 4,
    pfName: '소프트웨어융합대학 동아리'
  },
  {
    pfId: 5,
    pfName: 'SPARK'
  },
  {
    pfId: 6,
    pfName: 'SW중심대학사업단'
  },
  {
    pfId: 7,
    pfName: '킹고코인'
  },
];

export const getPlatformByPfId = (pfId: number) =>
  SITES.find((it) => it.pfId === pfId);

export const getPlatformByPfName = (pfName: string) =>
  SITES.find((it) => it.pfName === pfName);

/** 하드코딩된 FAQ 정보 */
export const FAQS = [
  {
    faqId: 0,
    question: "Q. 킹고코인에 사용기한이 있나요?",
    answer:
      "A. 킹고코인은 매년 3월 1일부터 다음해 2월 말일까지 적립되어, 매년 3월 1일에 전년 적립된 킹고코인이 소멸됩니다. 따라서, 보유된 코인이 사라지기 전에 적절하게 사용하는 것이 권장됩니다.",
  },
  {
    faqId: 1,
    question: "Q. 킹고코인을 어디에 사용할 수 있나요?",
    answer:
      "A. 2024년도부터 적립된 킹고코인은 우수 실적자 장학금(인당 30만원) 수여, 각종 행사 선발시 가산점 등에 적용되며, ChatGPT와 Colab 등 유료 구독 계정 사용시 또는 각종 IT 장비 대여시 차감 사용할 수 있습니다.",
  },
  {
    faqId: 2,
    question: "Q. 타과생도 킹고코인을 사용할 수 있나요?",
    answer:
      "A. 킹고코인은 킹고 ID를 가진 성균관대학교 구성원이라면 누구나 사용할 수 있습니다.",
  },
  {
    faqId: 3,
    question: "Q. 휴학생도 킹고코인을 사용할 수 있나요?",
    answer:
      "A. 성균관대학교 학생이라면 학년, 휴학유무와 관계없이 킹고코인을 획득하거나 사용하실 수 있습니다. 다만 혜택에 있어서 휴학유무에 따라 각종 행사 내 선발 기준에 제한이 있을 수 있습니다.",
  },
];


// API 부분 수정중 ~ing
// swagger 문서에 따라 순서대로 정리함

// 1. Student. 학생(student) API

// GET /api/student/{UserId}/detail 학생 정보 조회 (getStudentDetail)
export interface IStudentDetail {
  userId: 0,
  glsId: string,
  perId: 0,
  dept: string,
  name: string,
  degree: string,
  status: string,
  group: string,
  role: string
}

export const getStudentDetail = async (
  userId: number
): Promise<IStudentDetail> => {
  const path = PROD_LOGIN + `/` + ROUTE.STUDENT + `/${userId}/detail`;
  console.log("getStudentDetail path: ", path);
  const response = await clientWithToken.get(path);
  return response.data;
};

// GET /api/stduent 학생 리스트 조회 (getStudentsBySearch)
export interface ISearchOptions {
  column?: string; // optional
  search?: string; // optional
  order: "asc" | "desc"; // required
}

export interface IStudentListItem {
  userId: number;
  stId: number;
  stName: string;
  stDept: string;
  pointTotal: number;
  pointPlus: number;
}

export interface IGetStudentsBySearchResponse {
  totalCount: number;
  studentCoinResponse: IStudentListItem[];
}

export interface IPageOptions {
  page: number;
  size: number;
}

export const getStudentsBySearch = async (
  { order = "desc", column = "all", search = "" }: ISearchOptions,
  { page, size }: IPageOptions,
): Promise<IGetStudentsBySearchResponse> => {
  let path = `/?order=${order}&page=${page}&size=${size}`;

  if (column !== "all") {
    path += `&column=${column}`;
  }
  if (search) {
    path += `&search=${search}`;
  }

  const response = await clientWithToken.get(ROUTE.STUDENT + path);
  const data = response.data;

  const totalCount = data.totalCount; // Assuming the API returns the total count
  const studentCoinResponse = data.studentCoinResponse.map((it: IStudentListItem) => ({
    ...it,
    id: it.userId,
  }));

  console.log(order, column, search);
  console.log("page: ", page, "size: ", size, "data: ", data);

  return { totalCount, studentCoinResponse };
};


// 2. Policy 정책(Policy) API

// POST /api/policy/request 정책 생성 및 수정 요정 (postPolicyRequest)
interface IPolicyRequest {
  pfId: number;
  plId: string;
  plName: string;
  point: number;
  plus: boolean;
  active: boolean;
}

export const postPolicy = async ({
  pfId,
  plId,
  plName,
  point,
  plus,
  active,
}: IPolicyRequest): Promise<{ rqId: number }> => {
  console.log(plId);
  let path = plId != '' ? `/?plId=${plId}` : '/';

  const response = await clientWithToken.post(ROUTE.POLICY + path, {
    // plId는 요청 본문에 포함하지 않습니다.
    pfId,
    plName,
    point,
    plus,
    active,
  })

  console.log("Policy Request: ", response.data);

  return response.data;
};

// GET /api/policy 정책 조회 (getPoliciesByAdId)
export interface IPolicy {
  plId: number;
  plName: string;
  pfName: string;
  plus: boolean;
  point: number;
  active: boolean;
}

export interface IGetPoliciesResponse {
  totalCount: number;
  policyResponse: IPolicy[];
}

export const getPolicies = async (only?: "me", plus?: boolean, active?: boolean, page: number = 0, size: number = 1000): Promise<IGetPoliciesResponse> => {
  let path = `/`;
  console.log(only, plus, active, page, size);
  const params = new URLSearchParams();
  if (only) {
    params.append('only', only);
  }
  if (plus !== undefined) {
    params.append('plus', plus.toString());
  }
  if (active !== undefined) {
    params.append('active', active.toString());
  }
  params.append('page', page.toString());
  params.append('size', size.toString());

  if (params.toString()) {
    path += `?${params.toString()}`;
  }

  console.log("path: ", ROUTE.POLICY + path);

  const response = await clientWithToken.get(ROUTE.POLICY + path);
  const data = response.data
  console.log(data);

  const totalCount = data.totalCount;
  const policyResponse = data.policyPlatformResponses.map((it: IPolicy) => ({
    ...it,
    id: it.plId,
  }));

  return { totalCount, policyResponse };
}

// 3. Dev 개발용 API

// GET /api/dev/token/student 학생용 JWT 토큰 발급받기
// export const getStudentDevToken = async () => {
//   // const path = `/token/student?key=ssa-dev-key-v1`;
//   const path = `/token?key=ssa-dev-key-v1`;
//   const result = await client.get(ROUTE.DEV + path);
//   console.log("result:", result);
//   const accessToken = result.data;
//   setAccessCookie(accessToken);
//   refreshClientToken();
// };

// GET /api/dev/token/admin
export const getAdminDevToken = async () => {
  const path = `/token/admin?key=ssa-dev-key-v1`;
  const result = await client.get(ROUTE.DEV + path);
  const accessToken = result.data;
  setAccessCookie(accessToken);
  refreshClientToken();
};

// GET /api/dev/token/claims JWT 토큰의 클레임 가져오기
export interface IAuth {
  userId: number;
  role: USER_ROLE;
}

export const getJWTClaims = (token: string): IAuth | null => {
  try {
    const decoded = jwtDecode<{ userId: number, role: USER_ROLE }>(token);
    return {
      userId: decoded.userId,
      role: decoded.role
    };
  } catch (error) {
    console.error("JWT Decoding error:", error);
    return null;
  }
};

export const isAdmin = (token: string): boolean => {
  try {
    const decoded = jwtDecode<{ role: USER_ROLE }>(token); // 토큰을 디코딩하여 role 추출
    return decoded.role === USER_ROLE.ADMIN; // role이 ADMIN인지 확인
  } catch (error) {
    console.error("JWT Decoding error:", error);
    return false; // 디코딩 오류 발생 시 false 반환
  }
};

// apiManger.ts에서 사용하는 부분. swagger에는 없다.
export const refreshAccessToken = async (
  refreshToken: string
): Promise<string> => {
  const path = `/refresh?refreshToken=${refreshToken}`;
  const response = await clientWithToken.get(ROUTE.AUTH + path);
  return response.data;
};

// 4. Coin 코인(Coin) API
interface IManualCoin {
  stId: number;
  provider: string;
  stName: string;
  title: string;
  point: number;
  plId: number;
  plus: boolean;
  gainedDate: string;
}
// POST /api/coin/point/manual/grant 코인 수동 부여 (postManualPoint)
// 현재는 /api/coin/point
export const postCoin = async ({
  stId,
  stName,
  provider,
  title,
  point,
  plId,
  plus,
  gainedDate,
}: IManualCoin): Promise<{ dtId: number }> => {
  const path = `/point`;
  const response = await clientWithToken.post(ROUTE.COIN + path, {
    stId,
    stName,
    stDept: "소프트웨어학과",
    provider,
    title,
    point,
    plId,
    plus,
    gainedDate
  });

  console.log(path);
  console.log(ROUTE.COIN + path);
  console.log("in postMaual ", response);

  return response.data;
};

// POST /api/coin/point/auto 코인 자동 부여 (postAutoPoint)
interface IAutoGrantedCoin {
  stId: number;
  stName: string;
  key: string;
  plId: number;
  provider: string;
  plus: boolean;
}

export const postAutoPoint = async ({
  stId,
  stName,
  key,
  plId,
  provider,
  plus,
}: IAutoGrantedCoin): Promise<number> => {
  const path = `/point/auto`;
  const response = await clientWithToken.post(ROUTE.COIN + path, {
    stId,
    stName,
    key,
    plId,
    provider,
    plus,
  });
  return response.data;
};

interface IPostCoin {
  gainedDate: string,
  provider: string,
  point: number,
  title: string,
  stId: number,
  stName: string,
  stDept: string,
  plus: boolean
}

export const postPoint = async ({
  gainedDate,
  provider,
  point,
  title,
  stId,
  stName,
  stDept,
  plus
}: IPostCoin): Promise<number> => {
  const path = '/point';
  try {
    const response = await clientWithToken.post(ROUTE.COIN + path, {
      gainedDate,
      provider,
      point,
      title,
      stId,
      stName,
      stDept,
      plus
    });

    console.log(response.data);
    return response.data;
  }
  catch (error) {
    console.error("해당 관리자가 없습니다. adId = {adId}");
    throw error;
  }
}

// GET /api/coin/{userId} 학생 총 보유 포인트 조회 (getCoin)
export interface ICoin {
  coinId: number;
  userId: number;
  pointTotal: number;
  pointPlus: number;
  pointMinus: number;
  createdDate: string;
  modifiedDate: string;
}

export const getCoin = async (userId: number): Promise<ICoin> => {
  const path = `/${userId}`;
  const response = await clientWithToken.get(ROUTE.COIN + path);
  return response.data;
};

// GET /api/coin/{userId}/detail 학생의 최근 거래 내역 조회 (getCoinDetailByStudent)
export interface ICoinDetail {
  dtId: number;
  coinId: number;
  pointTotal: number;
  plId: number;
  title: string;
  plus: boolean;
  point: number;
  provider: string;
  createdDate: string;
  modifiedDate: string;
  gainedDate: string;
}

export interface IGetCoinDetailResponse {
  totalCount: number;
  coinDetail: ICoinDetail[];
}

export const getCoinDetailByStudent = async (
  userId: number,
  page: number,
  size: number
): Promise<IGetCoinDetailResponse> => {
  const path = `/${userId}/detail?page=${page}&size=${size}`;
  console.log("page: ", page, "size: ", size);
  const response = await clientWithToken.get(ROUTE.COIN + path);

  console.log("유저 아이디: ", userId);
  console.log("코인내역: ", response.data);

  const totalCount = response.data.totalCount;
  const coinDetail = response.data.coinDetailResponse.map((it: ICoinDetail, index: number) => ({
    ...it,
    id: it.dtId || index, // dtId가 없는 경우 index를 사용
  }));
  console.log(totalCount, coinDetail);

  return { totalCount, coinDetail };
};


// GET /api/coin/admin/detail 관리자의 최근 부여 내역 조회 (getCoinDetailByAdmin)
export const getCoinDetailByAdmin = async (
  page: number,
  size: number
): Promise<IGetCoinDetailResponse> => {
  const path = `/admin/detail?page=${page}&size=${size}`;
  console.log(ROUTE.COIN + path);
  const response = await clientWithToken.get(ROUTE.COIN + path);
  console.log(response.data);

  const totalCount = response.data.totalCount;
  const coinDetail = response.data.userCoinDetailResponses.map((it: ICoinDetail, index: number) => ({
    ...it,
    id: it.dtId || index, // dtId가 없는 경우 index를 사용
  }));

  return { totalCount, coinDetail };
};

// DELETE /api/coin/admin/detail 관리자의 코인 부여 내역 삭제 (deletePoint)
export const deletePoint = async (id: number): Promise<void> => {
  const path = `/point/${id}/delete`;
  const response = await clientWithToken.post(ROUTE.COIN + path);
  console.log(response.data);
};


/*
 
아래로는 legacy
혹시 몰라서 일단 주석 처리해놓음
 
*/

/* Coin api */

// export interface ICoin {
//   coinId: number;
//   userId: number;
//   pointTotal: number;
//   pointPlus: number;
//   pointMinus: number;
//   createdDate: string;
//   modifiedDate: string;
// }

// export const getCoin = async (userId: number): Promise<ICoin> => {
//   const path = `/${userId}`;
//   const response = await client.get(ROUTE.COIN + path);
//   return response.data;
// };
// getCoin 사용 예제
// const {
//   isLoading: userCoinIsLoading,
//   error: userCoinError,
//   data: userCoin,
// } = useQuery(["userCoin"], () => getCoin(userId));

// interface ICoinDetail {
//   dtId: number;
//   coinId: number;
//   pointTotal: number;
//   plus: boolean;
//   point: number;
//   plId: number;
//   plName: string;
//   title: string;
//   adId: number;
//   adGroup: string;
//   provider: string;
//   createdDate: string;
//   modifiedDate: string;
// }

// export const getCoinDetail = async (userId: number): Promise<ICoinDetail[]> => {
//   const path = `/${userId}/detail`;
//   const response = await client.get(ROUTE.COIN + path);

//   // MUI를 위한 id 추가
//   const result = response.data.map((it: ICoinDetail) => ({
//     ...it,
//     id: it.dtId,
//   }));
//   return result;
// };
// getCoinDetail 사용 예제
// const {
//   isLoading: userCoinDetailIsLoading,
//   error: userCoinDetailError,
//   data: userCoinDetail,
// } = useQuery(["userCoinDetail", userId], () => getCoinDetail(userId));

// export const getCoinDetailByAdId = async (
//   adId: number
// ): Promise<ICoinDetail[]> => {
//   const path = `/admin/detail`;
//   const response = await clientWithToken.get(ROUTE.COIN + path);

//   // MUI를 위한 id 추가
//   const result = response.data.map((it: ICoinDetail) => ({
//     ...it,
//     id: it.dtId,
//   }));
//   return result;
// };
// getCoinDetailByAdId 사용 예제
// const {
//   isLoading: adCoinDetailIsLoading,
//   error: adCoinDetailError,
//   data: adCoinDetail,
// } = useQuery(["adCoinDetail"], () => getCoinDetailByAdId(adId));

// interface IGrantedCoin {
//   stId: number;
//   stName: string;
//   plId: number;
//   title: string;
//   plus: boolean;
//   point: number;
//   gainedDate: string;
// }

// export const postManualCoin = async ({
//   stId,
//   stName,
//   plId,
//   title,
//   plus,
//   point,
//   gainedDate,
// }: IGrantedCoin): Promise<{ dtId: number }> => {
//   const path = `/point/manual`;
//   const response = await clientWithToken.post(ROUTE.COIN + path, {
//     stId,
//     stName,
//     title,
//     point,
//     plId,
//     plus,
//     gainedDate,
//   });

//   return response.data;
// };

/* User api */

// export interface ISearchOptions {
//   order?: "asc" | "desc";
//   column?: string;
//   search?: string;
// }

// interface IUser {
//   userId: number;
//   stId: number;
//   stName: string;
//   dept: string;
//   pointTotal: number;
//   pointPlus: number;
//   role: string;
// }

// export const getUsersBySearch = async ({
//   order = "desc", // 코인 보유량 정렬 기본값: 내림차순
//   column,
//   search,
// }: ISearchOptions): Promise<IUser[]> => {
//   let path = `/?order=${order}&column=${column}&search=${search}`;

//   // (column == undefined || search == undefined): 모든 유저 검색
//   if (!column || !search) path = `/?order=${order}`;

//   // MUI를 위한 id 추가
//   const response = await client.get(ROUTE.USER + path);
//   const result = response.data.map((it: IUser) => ({ ...it, id: it.userId }));
//   return result;
// };
// getUsersBySearch 사용 예제
// const {
//   isLoading: usersIsLoading,
//   error: usersError,
//   data: users,
// } = useQuery(["users"], () => getUsersBySearch(searchOptions));

// export interface IUserDetail {
//   stId: number;
//   stName: string;
//   stDegree: string;
//   stStatus: string;
//   stDept: string;
// }

// export const getUserDetail = async (userId: number): Promise<IUserDetail> => {
//   const path = `/detail/${userId}`;
//   const response = await client.get(ROUTE.USER + path);
//   return response.data;
// };
// getUserDetail 사용 예제
// const {
//   isLoading: userDetailIsLoading,
//   error: userDetailError,
//   data: userDetail,
// } = useQuery(["userDetail", userId], () => getUserDetail(userId));

/* Policy api */

// export interface IPolicy {
//   plId: number;
//   plName: string;
//   plCode: string;
//   pfName: string;
//   plus: boolean;
//   point: number;
//   available: boolean;
// }

// export const getPolicies = async (only?: "me"): Promise<IPolicy[]> => {
//   let path = `/`;
//   if (only) path = `/?only=${only}`;
//   const response = await clientWithToken.get(ROUTE.POLICY + path);

//   // MUI를 위한 id 추가
//   const result = response.data.map((it: IPolicy) => ({
//     ...it,
//     id: it.plId,
//   }));
//   return result;
// };
// // getPolicies 사용 예제
// // const {
// //   isLoading: policiesIsLoading,
// //   error: policiesError,
// //   data: policies,
// // } = useQuery(["policies"], () => getPolicies());

// interface IPolicyRequest {
//   plId?: number;
//   pfId: number;
//   rqName: string;
//   rqPlus: boolean;
//   rqPoint: number;
//   rqReason: string;
//   rqType: POLICY_REQUEST_TYPE;
// }

// export const postPolicyRequest = async ({
//   plId,
//   pfId,
//   rqName,
//   rqPlus,
//   rqPoint,
//   rqReason,
//   rqType,
// }: IPolicyRequest): Promise<{ rqId: number }> => {
//   let path = `/request?plId=${plId}`;
//   if (rqType === "CREATE" || !plId) path = `/request`;
//   const result = await clientWithToken.post(ROUTE.POLICY + path, {
//     plId,
//     pfId,
//     rqName,
//     rqPoint,
//     rqReason,
//     rqPlus,
//     rqType,
//   });

//   return result.data;
// };

/* Statics api */

export interface IStaticsByMonth {
  stId: number;
  year: number;
  month: number;
  point: number;
}

export interface IGetStaticsByMonth {
  totalCount: number;
  staticsResponses: IStaticsByMonth[];
}

export const getStaticsByMonth = async (
  userId: number,
  { page, size }: IPageOptions
): Promise<IGetStaticsByMonth> => {
  // API 엔드포인트 URL 구성
  const url = PROD_LOGIN + `/api/statics/${userId}?page=${page}&size=${size}`;
  console.log("getStaticsByMonth URL: ", url);

  // 인터셉터를 통해 에러를 처리하기 때문에 별도의 try-catch가 필요 없음
  const response = await clientWithToken.get(url);
  const data = response.data;
  console.log("월별 통계: ", data);

  const totalCount = data.totalCount;
  // MUI를 위한 id 추가
  const staticsResponses = data.staticsResponses.map((it: IStaticsByMonth) => ({
    ...it,
    id: it.stId,
  }));

  return { totalCount, staticsResponses };
};

/* Dev api */

// TODO: 업로드시 삭제
// export const getDevToken = async () => {
//   const path = `/token?key=ssa-dev-key-v1`;
//   const result = await client.get(ROUTE.DEV + path);
//   const accessToken = result.data;
//   setAccessCookie(accessToken);
//   refreshClientToken();
// };

// export interface IAuth {
//   userId: number;
//   role: USER_ROLE;
// }

// export const getJWTClaims = async (accessToken: string): Promise<IAuth> => {
//   const path = `/token/claims?token=${accessToken}`;
//   const response = await client.get(ROUTE.DEV + path);
//   return response.data;
// };

// /* Auth api */

// export const refreshAccessToken = async (
//   refreshToken: string
// ): Promise<string> => {
//   const path = `/refresh?refreshToken=${refreshToken}`;
//   const response = await clientWithToken.get(ROUTE.AUTH + path);
//   return response.data;
// };