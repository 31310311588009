import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardContent, CardHeader, Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams, GridLogicOperator } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { getCoinDetailByStudent, ICoinDetail } from "../../../common/api";
import dayjs from "dayjs";
import { dayjsToFormat, stampToDayjs } from "../../../common/apiManager";
import CustomPagination from "../../CustomPagination";
import Status from "../../feedback/Status";

interface UserCoinDetailCardProps {
  userId: number;
  pageSize: number;
  className: string;
}

const UserCoinDetailCard = ({ userId, pageSize, className }: UserCoinDetailCardProps) => {
  const [page, setPage] = useState(0); // 현재 페이지 상태 관리
  const [sortedCoinDetail, setSortedCoinDetail] = useState<ICoinDetail[]>([]);
  const [totalRows, setTotalRows] = useState(0); // 총 아이템 수 상태 관리
  const [selectedRow, setSelectedRow] = useState<ICoinDetail | null>(null); // 선택된 행 상태 관리
  const [open, setOpen] = useState(false); // 모달 열림 상태 관리

  const isSmDown = useMediaQuery('(max-width: 640px)'); // sm 크기 이하의 화면 감지

  // useQuery를 사용하여 데이터 가져오기, page 상태가 변경될 때마다 쿼리를 다시 실행
  const {
    isLoading: userCoinDetailIsLoading,
    error: userCoinDetailError,
    data: userCoinDetail,
    refetch,
  } = useQuery(["userCoinDetail", userId, page, pageSize], () => getCoinDetailByStudent(userId, page, pageSize), {
    onSuccess: (data) => {
      const sortedData = [...data.coinDetail].sort((a, b) => dayjs(b.gainedDate).diff(dayjs(a.gainedDate)));
      setSortedCoinDetail(sortedData);
      setTotalRows(data.totalCount); // 총 아이템 수 업데이트
    },
    keepPreviousData: true, // 페이지네이션을 위해 이전 데이터 유지
  });

  useEffect(() => {
    refetch();
  }, [page]);

  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    refetch();
  };

  const handleRowClick = (params: any) => {
    setSelectedRow(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: "gainedDate",
      headerName: "날짜",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        dayjsToFormat(stampToDayjs(params.row.gainedDate)),
      sortable: false,
      headerClassName: "text-xxs xs:text-xs md:text-sm",
      cellClassName: "text-xxs xs:text-xs md:text-sm",
    },
    {
      field: "provider",
      headerName: "제공처",
      flex: 1.0,
      sortable: false,
      headerClassName: "text-xxs xs:text-xs md:text-sm",
      cellClassName: "text-xxs xs:text-xs md:text-sm",
    },
    {
      field: "title",
      headerName: "내용",
      flex: 0.8,
      sortable: false,
      headerClassName: "text-xxs xs:text-xs md:text-sm",
      cellClassName: "text-xxs xs:text-xs md:text-sm",
    },
    {
      field: "coinChange",
      headerName: "코인 변동",
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.plus ? `+${params.row.point}` : `-${params.row.point}`,
      sortable: false,
      headerClassName: "text-xxs xs:text-xs md:text-sm",
      cellClassName: "text-xxs xs:text-xs md:text-sm",
    },
    {
      field: "pointTotal",
      headerName: "보유 코인",
      flex: 0.5,
      sortable: false,
      headerClassName: "text-xxs xs:text-xs md:text-sm",
      cellClassName: "text-xxs xs:text-xs md:text-sm",
    },
  ];

  const columnsToShow = useMemo(() => {
    if (isSmDown) {
      return columns
        .filter(col => col.field === "gainedDate" || col.field === "title" || col.field === "coinChange")
        .map(col => ({
          ...col,
          flex: col.flex ? col.flex * 1.5 : 1, // 열이 줄어들면 flex를 증가시킵니다.
        }));
    }
    return columns;
  }, [isSmDown, columns]);

  return (
    <Card className={className}>
      <CardHeader
        title="코인 내역"
        titleTypographyProps={{ variant: "display" }}
      />
      <CardContent>
        <Status
          isLoading={userCoinDetailIsLoading}
          error={userCoinDetailError}
          isData={sortedCoinDetail && sortedCoinDetail.length > 0}
          className="h-[318.4px]"
        />
        {!userCoinDetailIsLoading && !userCoinDetailError && sortedCoinDetail && (
          <div className="h-[318.4px] flex flex-col">
            <DataGrid
              className="flex-grow w-full"
              rows={sortedCoinDetail}
              columns={columnsToShow}
              disableColumnMenu
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                    page: 0,
                  },
                },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLogicOperator.Or,
                  },
                },
              }}
              pageSizeOptions={[pageSize]}
              rowCount={totalRows} // 총 아이템 수 설정
              paginationMode="server"
              sortingMode="server"
              disableRowSelectionOnClick
              onRowClick={handleRowClick}
              components={{
                Pagination: () => (
                  <CustomPagination
                    pageCount={Math.ceil(totalRows / pageSize)} // 페이지 수 계산
                    page={page}
                    onPageChange={handlePageChange}
                  />
                ),
              }}
              componentsProps={{
                footer: { className: 'border-0 justify-center items-center' },
              }}
            />
          </div>
        )}
      </CardContent>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>코인 내역 상세 정보</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <div className="flex flex-col gap-2">
              <p><strong>날짜:</strong> {dayjsToFormat(stampToDayjs(selectedRow.gainedDate))}</p>
              <p><strong>제공처:</strong> {selectedRow.provider}</p>
              <p><strong>내용:</strong> {selectedRow.title}</p>
              <p><strong>코인 변동:</strong> {selectedRow.plus ? `+${selectedRow.point}` : `-${selectedRow.point}`}</p>
              <p><strong>보유 코인:</strong> {selectedRow.pointTotal}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default UserCoinDetailCard;
