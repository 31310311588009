import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Card, CardContent, CardHeader, TextField, Select, MenuItem, FormControl, InputLabel, Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridLogicOperator, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { IStudentListItem, ISearchOptions, IPageOptions, getStudentsBySearch } from "../../../common/api";
import Status from "../../feedback/Status";
import { SelectChangeEvent } from '@mui/material/Select';
import CustomPagination from "../../../components/CustomPagination";

interface UserSearchCardProps {
  pageSize: number;
  handleRowClick: (params: GridRowParams) => void;
  className?: string;
}

const UserSearchCard = ({
  pageSize,
  handleRowClick,
  className,
}: UserSearchCardProps) => {
  const [searchText, setSearchText] = useState("");
  const [searchField, setSearchField] = useState("stName");
  const [searchOrder, setSearchOrder] = useState<"asc" | "desc">("desc");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(4);
  const [users, setUsers] = useState<IStudentListItem[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [endSize, setEndSize] = useState(0);

  const searchOptions: ISearchOptions = {
    search: searchText.trim() === "" ? "" : searchText,
    column: searchText.trim() === "" ? "all" : searchField,
    order: searchOrder,
  };

  const pageOptions: IPageOptions = {
    page: page,
    size: 4,
  };

  const fetchStudents = async (searchOptions: ISearchOptions, pageOptions: IPageOptions) => {
    const data = await getStudentsBySearch(searchOptions, pageOptions);
    return data;
  };

  const { isLoading: usersIsLoading, error: usersError, refetch } = useQuery(
    ["students", searchOptions, pageOptions],
    () => fetchStudents(searchOptions, pageOptions),
    {
      keepPreviousData: true,
      onSuccess: (newData) => {
        console.log(newData);
        setEndSize(newData.totalCount);
        setTotalRows(newData.studentCoinResponse.length);
        setUsers(newData.studentCoinResponse);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: false,
    }
  );

  useEffect(() => { // 첫 렌더링 전에 쿼리 보내서 전체 학생 리스트 뜨도록
    refetch();
  }, []);

  useEffect(() => { // page 바뀔 때 마다 쿼리 보내서 
    refetch();
  }, [page]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSearchFieldChange = (event: SelectChangeEvent<string>) => {
    setSearchField(event.target.value);
  };

  const handleSearchClick = () => {
    setPage(0);
    refetch();
  };

  const onSortChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setSearchOrder(sort || "asc");
      setSearchField(field);
      setPage(0);
      refetch();
    }
  };

  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    refetch()
  };

  const displayData = users.map((user) => ({ ...user, id: user.userId }));

  const columns = [
    { field: "stName", headerName: "이름", flex: 1 },
    { field: "stId", headerName: "학번", flex: 1 },
    { field: "stDept", headerName: "학과", flex: 2 },
    { field: "pointTotal", headerName: "보유코인", flex: 1 }
  ];

  return (
    <div className={className}>
      <Card className="relative w-full h-full">
        <CardHeader
          title="사용자 조회"
          titleTypographyProps={{ variant: "display" }}
        // titleTypographyProps={{ variant: "h6" }}
        // subheaderTypographyProps={{ variant: "caption", className: "mt-2" }}
        />
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="search-field-label">검색 필드</InputLabel>
            <Select
              labelId="search-field-label"
              id="search-field"
              value={searchField}
              onChange={handleSearchFieldChange}
              label="검색 필드"
            >
              <MenuItem value="stName">이름</MenuItem>
              <MenuItem value="stId">학번</MenuItem>
              <MenuItem value="stDept">학과</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            placeholder="검색어 입력..."
            size="small"
            fullWidth
            value={searchText}
            onChange={handleSearchChange}
          />
          <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchClick}>
            <SearchIcon />
          </IconButton>
        </Box>
        <CardContent>
          <Status
            isLoading={usersIsLoading}
            error={usersError}
            isData={users && users.length > 0}
            className="h-[318.4px]"
          />
          {!usersIsLoading && !usersError && users && (
            <div>
              <DataGrid
                className="h-[318.4px]"
                rows={displayData}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 4,
                      page: 0,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterLogicOperator: GridLogicOperator.Or,
                    },
                  },
                }}
                pageSizeOptions={[size]}
                rowCount={totalRows}
                paginationMode="server"
                sortingMode="server"
                onSortModelChange={onSortChange}
                disableRowSelectionOnClick
                onRowClick={handleRowClick}
                components={{
                  Pagination: () => (
                    <CustomPagination
                      pageCount={Math.ceil(endSize / size)}
                      page={page}
                      onPageChange={handlePageChange}
                    />
                  ),
                }}
                componentsProps={{
                  footer: { className: 'border-0 justify-center items-center' },
                }}
              />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UserSearchCard;
