import { useState } from "react";
import { POLICY_REQUEST_TYPE, getAccessCookie, checkTokenExp } from "../common/apiManager";
import CreatePolicyCard from "../components/cards/policy/CreatePolicyCard";
import UpdatePolicyCard from "../components/cards/policy/UpdatePolicyCard";
import PolicySearchCard from "../components/cards/table/PolicySearchCard";
import PlaceholderCard from "../components/cards/policy/PolicyPlaceholderCard";
import { Grow } from "@mui/material";
import { useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { deleteAllCookies } from "../common/utils";
import { isAdmin } from "../common/api";
import RedirectDialog from "../components/RedirectDialog";


interface IPolicyCard {
  type: POLICY_REQUEST_TYPE;
  plId?: number;
}

const Policies = () => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [policyCard, setPolicyCard] = useState<IPolicyCard>({
    type: POLICY_REQUEST_TYPE.EMPTY,
    plId: undefined,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = getAccessCookie();
    console.log("Policies 페이지", accessToken);

    if (!accessToken) { // 토큰이 없는 경우
      navigate('/');
      return;
    }
    if (!checkTokenExp(accessToken)) { // 토큰이 만료된 경우
      deleteAllCookies();
      setIsDialogOpen(true);
      return;
    }
    if (!isAdmin(accessToken)) {
      navigate('/dashboard')
    }
  }, [navigate]);

  const handleConfirm = () => {
    setIsDialogOpen(false);
    navigate('/'); // 다이얼로그에서 확인 버튼을 누르면 로그인 화면으로 리다이렉트
  };

  const handleRowClick = (type: POLICY_REQUEST_TYPE, plId?: number) => {
    setOpen(false);
    setPolicyCard({ type, plId });
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  return (
    <>
      <RedirectDialog
        open={isDialogOpen}
        handleConfirm={handleConfirm}
      >
        토큰이 만료되어 로그아웃되었습니다. 로그인 화면으로 이동합니다.
      </RedirectDialog>
      <section className="flex gap-6">
        <PolicySearchCard
          handleRowClick={handleRowClick}
          className="w-[662px] h-[500px]"
        />
        {policyCard.type === POLICY_REQUEST_TYPE.EMPTY && (
          <PlaceholderCard className="w-[466px]">
            목록에서 정책을 선택하세요.
          </PlaceholderCard>
        )}
        <Grow in={open} timeout={100}>
          <div>
            {policyCard.type === POLICY_REQUEST_TYPE.CREATE && (
              <CreatePolicyCard
                className="w-[466px] h-[500px]"
                reasonRow={4}
              />
            )}
            {policyCard.type === POLICY_REQUEST_TYPE.UPDATE && (
              <UpdatePolicyCard
                plId={policyCard.plId!}
                reasonRow={4}
                className="w-[466px] h-[500px]"
              />
            )}
          </div>
        </Grow>
      </section>
    </>
  );
};

export default Policies;
