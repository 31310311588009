import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCoinDetailByAdmin, deletePoint } from "../../../common/api";
import { dayjsToFormat, stampToDayjs } from "../../../common/apiManager";
import CustomPagination from "../../CustomPagination";
import Status from "../../feedback/Status";

interface AdminCoinDetailCardProps {
  adId: number;
  pageSize: number;
}

const AdminCoinDetailCard = ({ adId, pageSize }: AdminCoinDetailCardProps) => {
  const [page, setPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    isLoading: adCoinDetailIsLoading,
    error: adCoinDetailError,
    data: adCoinDetail,
    refetch
  } = useQuery(
    ["adCoinDetail", page, pageSize],
    () => getCoinDetailByAdmin(page, pageSize),
    {
      keepPreviousData: true,
    }
  );

  const deleteMutation = useMutation(deletePoint, {
    onSuccess: () => {
      queryClient.invalidateQueries(["adCoinDetail", page, pageSize]);
    },
  });

  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    refetch();
  };

  const handleDelete = () => {
    selectedIds.forEach(id => {
      deleteMutation.mutate(id);
    });
    setSelectedIds(new Set<number>());
    setOpen(false);
  };

  const handleSelectionChange = (newSelection: number[]) => {
    setSelectedIds(new Set(newSelection));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const render =
    !adCoinDetailIsLoading &&
    !adCoinDetailError &&
    adCoinDetail &&
    adCoinDetail.coinDetail.length > 0;

  const columns: GridColDef[] = [
    {
      field: "gainedDate",
      headerName: "날짜",
      flex: 1,
      valueGetter: (params: GridCellParams) =>
        dayjsToFormat(stampToDayjs(params.row.gainedDate)),
      cellClassName: (params: GridCellParams) =>
        params.row.deleted ? 'line-through' : ''
    },
    {
      field: "perId",
      headerName: "학번",
      flex: 1,
      sortable: false,
      cellClassName: (params: GridCellParams) =>
        params.row.deleted ? 'line-through' : ''
    },
    {
      field: "name",
      headerName: "이름",
      flex: 1,
      sortable: false,
      cellClassName: (params: GridCellParams) =>
        params.row.deleted ? 'line-through' : ''
    },
    {
      field: "title",
      headerName: "내용",
      flex: 1.5,
      sortable: false,
      cellClassName: (params: GridCellParams) =>
        params.row.deleted ? 'line-through' : ''
    },
    {
      field: "provider",
      headerName: "제공처",
      flex: 1.2,
      sortable: false,
      cellClassName: (params: GridCellParams) =>
        params.row.deleted ? 'line-through' : ''
    },
    {
      field: "coinChange",
      headerName: "코인 변동",
      flex: 1,
      sortable: false,
      valueGetter: (params: GridCellParams) => params.row.plus ? `+${params.row.point}` : `-${params.row.point}`,
      cellClassName: (params: GridCellParams) =>
        params.row.deleted ? 'line-through' : ''
    },
  ];

  return (
    <Card className="flex-1">
      <CardHeader
        title="부여/차감한 코인 내역"
        titleTypographyProps={{ variant: "h6" }}
        action={
          <div className="flex items-center space-x-4">
            <Button
              variant="contained"
              onClick={handleClickOpen}
              disabled={selectedIds.size === 0}
            >
              내역 삭제
            </Button>
          </div>
        }
      />
      <CardContent>
        <Status
          isLoading={adCoinDetailIsLoading}
          error={adCoinDetailError}
          isData={adCoinDetail && adCoinDetail.coinDetail.length > 0}
          className="h-[318.4px]"
        />
        {render && (
          <DataGrid
            className="h-[318.4px]"
            rows={adCoinDetail.coinDetail.map((row) => ({ ...row, id: row.dtId }))}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu
            checkboxSelection
            hideFooterSelectedRowCount
            isRowSelectable={(params) => !params.row.deleted} // 삭제된 행은 선택할 수 없도록 설정
            onRowSelectionModelChange={(newSelection) => handleSelectionChange(newSelection as number[])}
            rowSelectionModel={Array.from(selectedIds)}
            initialState={{
              sorting: {
                sortModel: [{ field: 'gainedDate', sort: 'asc' }],
              },
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
            }}
            pageSizeOptions={[pageSize]}
            rowCount={adCoinDetail.totalCount}
            paginationMode="server"
            sortingMode="server"
            components={{
              Pagination: () => (
                <CustomPagination
                  pageCount={Math.ceil(adCoinDetail.totalCount / pageSize)}
                  page={page}
                  onPageChange={handlePageChange}
                />
              ),
            }}
            componentsProps={{
              footer: { className: 'border-0 justify-center items-center' },
            }}
            getCellClassName={(params: GridCellParams) => params.row.deleted ? 'line-through' : ''}
          />
        )}
      </CardContent>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>경고</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택된 내역을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AdminCoinDetailCard;