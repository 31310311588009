import { UploadFile } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import * as xlsx from "xlsx";
import * as FileSaver from "file-saver";
import { IPolicy, getPolicies, postCoin } from "../../../common/api";
import { PL_ID_MANUAL, formToGrantedCoin } from "../../../common/apiManager";
import ConfirmDialog from "../../ConfirmDialog";
import Status from "../../feedback/Status";

interface ExcelUploaderProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

interface ExcelCoinGrantCardProps {
  adId: number;
  maxRow: number;
  rowHeight?: number;
  onGrantSuccess: () => void;
}

interface IExcelForm {
  plId: string;
  pfName: string;
  title: string;
  point: number;
  adId: number;
  gainedDate: Dayjs;
  provider: string;
}

const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excelFileExtension = '.xlsx';
const excelFileName = 'Sample';

const SampleExcelForm = () => {
  const data = [{
    학과: "학과",
    학번: "학번",
    이름: "이름"
  }];

  const rows = xlsx.utils.json_to_sheet(data, {
    skipHeader: true
  });

  rows['!cols'] = [
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 }
  ];

  const wb: any = { Sheets: { data: rows }, SheetNames: ['data'] };
  const excelButter = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelFile = new Blob([excelButter], { type: excelFileType });
  FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
}

const ExcelUploader = ({ handleFileChange, className }: ExcelUploaderProps) => {
  return (
    <div className={className}>
      <div className="flex flex-col gap-4 flex-1 items-center justify-center w-full h-full p-4 border-dashed border-primary border-2 text-primary">
        <InputLabel
          htmlFor="file"
          className="flex flex-col gap-2 items-center text-primary cursor-pointer"
        >
          <div className="flex gap-2">
            <Typography variant="title-l">엑셀 파일을 업로드하세요.</Typography>
            <UploadFile className="w-6 h-6" />
          </div>
          <Typography variant="body">
            학번, 이름이 입력된 엑셀 파일로 한 번에 최대 100개까지의 데이터를 입력할 수 있습니다.
          </Typography>
        </InputLabel>
        <input
          hidden
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          id="file"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

const ExcelCoinGrantCard = ({
  adId,
  maxRow,
  rowHeight = 32,
  onGrantSuccess,
}: ExcelCoinGrantCardProps) => {

  const [form, setForm] = useState<IExcelForm>({
    plId: "",
    pfName: "",
    title: "",
    provider: "",
    point: 0,
    adId: adId,
    gainedDate: dayjs(),
  });

  const [isManual, setIsMenual] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    isLoading: policiesIsLoading,
    error: policiesError,
    data: policies,
  } = useQuery(["policies"], () => getPolicies(undefined, true, true));

  const render =
    !policiesIsLoading && !policiesError && policies && policies.policyResponse.length > 0;

  const [excelIsLoading, setExcelIsLoading] = useState(true);
  const [excel, setExcel] = useState<IExcelRow[]>([]);

  interface IExcelRow {
    학번: string;
    이름: string;
    학과: string;
  }

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet) as Array<IExcelRow>;

      if (json.some(row => row.학번 === undefined || row.이름 === undefined || row.학과 === undefined)) {
        alert("엑셀 파일 형식이 올바르지 않습니다. '학과', '학번', '이름' 열이 포함되어 있는지 확인해주세요.");
        setUploadedFile(null);
      } else {
        setExcel(json);
        setUploadedFile(file);
      }
      setExcelIsLoading(false);
    };
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    setExcel([]);
    setExcelIsLoading(true);
  };

  const handleGrantCoins = () => {
    excel.forEach((it) => {
      postCoin(
        formToGrantedCoin({
          ...form,
          stName: it.이름,
          stId: it.학번,
          stDept: it.학과,
        })
      ).then(() => {
        onGrantSuccess(); // 부여 성공 후 호출
        // 상태 초기화 코드 추가
        setForm({
          plId: "",
          pfName: "",
          title: "",
          provider: "",
          point: 0,
          adId: adId,
          gainedDate: dayjs(),
        });
        setExcel([]);
        setUploadedFile(null);
        setExcelIsLoading(true); // 업로더 상태 초기화
      });
    });
  };

  const columns: GridColDef[] = [
    { field: "학과", headerName: "학과", flex: 1, sortable: false },
    { field: "학번", headerName: "학번", flex: 1, sortable: false },
    { field: "이름", headerName: "이름", flex: 1, sortable: false },
  ];

  if (!policies) {
    return null;
  }

  const policyResponse = policies.policyResponse;

  const groupedPolicies: { [key: string]: IPolicy[] } = policyResponse.reduce((acc: { [key: string]: IPolicy[] }, policy) => {
    const { pfName } = policy;
    if (!acc[pfName]) {
      acc[pfName] = [];
    }
    acc[pfName].push(policy);
    return acc;
  }, {});

  return (
    <>
      <ConfirmDialog
        open={showModal}
        handleConfirm={() => {
          handleGrantCoins();
          setShowModal(false);
        }}
        handleCancel={() => setShowModal(false)}
      >
        {`${excel.length}명의 학생에게 ${form.point}코인을 부여하시겠습니까?`}
      </ConfirmDialog>
      <Card className="flex flex-col w-[662px]">
        <div className="flex justify-between items-center px-4 py-2">
          <CardHeader
            title={"엑셀로 코인부여"}
            titleTypographyProps={{ variant: "display" }}
            sx={{ paddingLeft: 0 }}
          />
          <Button variant="contained" onClick={() => SampleExcelForm()}>
            양식다운
          </Button>
        </div>
        <CardContent className="flex flex-col flex-1">
          <Status
            isLoading={policiesIsLoading}
            error={policiesError}
            isData={policyResponse && policyResponse.length > 0}
            className="flex-1"
          />
          <div className="flex flex-col">
            <div className="h-[170.4px]">
              {render && excelIsLoading && (
                <ExcelUploader handleFileChange={handleFileChange} className="h-full" />
              )}
              {render && !excelIsLoading && (
                <DataGrid
                  className="w-full overflow-y-scroll"
                  disableColumnMenu
                  rows={excel}
                  columns={columns}
                  getRowId={(row) => row.학번}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: maxRow,
                      },
                    },
                  }}
                  pageSizeOptions={[maxRow]}
                  rowHeight={rowHeight}
                  columnHeaderHeight={rowHeight}
                  hideFooter
                />
              )}
            </div>
            <hr />
            {render && (
              <div className="flex flex-col gap-3">
                <div className="flex gap-2">
                  <TextField
                    className="flex-[3]"
                    select
                    label="정책"
                    value={form.plId}
                    onChange={(e) => {
                      const selectedPolicyId = e.target.value;
                      const policy = policyResponse.find(p => p.plId.toString() === selectedPolicyId);
                      if (policy) {
                        setForm({
                          ...form,
                          plId: policy.plId.toString(),
                          pfName: policy.pfName,
                          point: policy.point,
                          title: policy.plName,
                          provider: policy.pfName,
                        });
                        setIsMenual(policy.plId === parseInt(PL_ID_MANUAL, 10));
                      }
                    }}
                    size="small"
                  >
                    {Object.entries(groupedPolicies).map(([providerName, policies]) => [
                      <MenuItem
                        key={providerName}
                        value=""
                        disabled
                        sx={{
                          fontWeight: 'bold',
                          color: 'text.primary',
                          opacity: 1,
                          '.Mui-disabled': {
                            opacity: 1,
                          },
                        }}
                      >
                        {providerName}
                      </MenuItem>,
                      ...policies.map(policy => (
                        <MenuItem key={policy.plId} value={policy.plId.toString()} style={{ marginLeft: '20px' }}>
                          {policy.plName}
                        </MenuItem>
                      ))
                    ])}
                  </TextField>
                  <TextField
                    className="flex-[2]"
                    disabled
                    label="제공처"
                    value={form.pfName}
                    size="small"
                  />
                </div>
                <Collapse in={isManual} mountOnEnter unmountOnExit>
                  <TextField
                    className="w-full"
                    placeholder="제목을 입력해주세요."
                    label="제목"
                    size="small"
                    value={form.title}
                    onChange={(e) => {
                      setForm({ ...form, title: e.target.value });
                    }}
                  />
                </Collapse>
                <div className="flex gap-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="flex-[3]"
                      value={form.gainedDate}
                      onChange={(value) => {
                        setForm({
                          ...form,
                          gainedDate: value || dayjs(),
                        });
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                  <div className="flex gap-2 flex-[2]">
                    <TextField
                      className="flex-1"
                      label="코인값"
                      type="number"
                      value={form.point}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          point: parseInt(e.target.value),
                        });
                      }}
                      size="small"
                    />
                    <div className="flex flex-col">
                      <Button
                        className="flex flex-col"
                        variant="contained"
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        코인부여
                      </Button>
                      {
                        uploadedFile && (
                          <Button
                            className="flex flex-col mt-2"
                            variant="outlined"
                            onClick={handleDeleteFile}
                          >
                            파일 삭제
                          </Button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ExcelCoinGrantCard;
